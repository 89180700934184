import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Rating from '@mui/material/Rating';
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Image from "components/widgets/Image";
import styled from "@emotion/styled";
import Button from "components/widgets/Button";
import BuyNowDialog from "components/dialogs/BuyNowDialog";
import PlaceBidDialog from "components/dialogs/PlaceBidDialog";
import AuctionCountdown from "./AuctionCountdown";
import BidsDialog from "components/dialogs/BidsDialog";
import UpdateReservePriceDialog from "components/dialogs/UpdateReservePriceDialog";
import { getReinventShippingOption } from 'data/vehicle.actions';
import ConfirmSaleDialog from "../../dialogs/ConfirmSaleDialog";
import { formatDollarAmount, formatDateTime } from "utils/Formatters";
import { loadOffer, getBuyer } from 'data/offer.actions';
import CancelAuctionDialog from "components/dialogs/CancelAuctionDialog";
import { hasBidPermissionOnHighestBid, hasRooftopPermission, permittedBuyRooftops } from "utils/User";

const PREFIX = 'Search';

const classes = {
  card: `${PREFIX}-card`,
  imageContainer: `${PREFIX}-imageContainer`,
  auctionImg: `${PREFIX}-auction-img`,
  auction: `${PREFIX}-auction`,
  views: `${PREFIX}-views`,
  eyeIcon: `${PREFIX}-eyeIcon`,
  dealer: `${PREFIX}-dealer`,
  location: `${PREFIX}-location`,
  btn: `${PREFIX}-btn`,
  flex: `${PREFIX}-flex`,
  mileage: `${PREFIX}-mileage`,
  vehicle: `${PREFIX}-vehicle`,
  kbb: `${PREFIX}-kbb`,
  price: `${PREFIX}-price`,
  detailpanel: `${PREFIX}-detail-panel`,
  review: `${PREFIX}-review`,
  name: `${PREFIX}-name`,
  valueFlex: `${PREFIX}-valueFlex`,
  box: `${PREFIX}-box`,
  errorBlock: `${PREFIX}-errorBlock`,
  successBlock: `${PREFIX}-successBlock`,
  scheduledBlock: `${PREFIX}-scheduledBlock`,
  reserve: `${PREFIX}-reserve`,
  btnGroup: `${PREFIX}-btnGroup`,
  bgbtn: `${PREFIX}-bgbtn`,
  bidIcon: `${PREFIX}-bidIcon`,
  bid: `${PREFIX}-bid`,
  blackbook: `${PREFIX}-blackbook`,
  auctionDetail: `${PREFIX}-auctionDetail`,
  buyNowAmount: `${PREFIX}-buyNowAmount`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => {
  return {
    [`& .${classes.card}`]: {
      background: theme.palette.background.default,
      boxShadow: '0px 4px 15px rgb(152 155 160 / 24%)',
      borderRadius: 20,
    },
    [`& .${classes.imageContainer}`]: {
      marginTop: 8
    },
    [`& .${classes.auctionImg}`]: {
      borderRadius: 16,
      height: 200,
    },
    [`& .${classes.auction}`]: {
      marginRight: 10,
      position: 'relative'
    },
    [`& .${classes.views}`]: {
      position: 'absolute',
      display: 'flex',
      background: 'rgba(255, 255, 255, 0.58)',
      backdropFilter: 'blur(37px)',
      borderRadius: 5,
      padding: 3,
      width: 60,
      top: 10,
      justifyContent: 'center',
      right: 10
    },
    [`& .${classes.dealer}`]: {
      position: 'absolute',
      display: 'flex',
      background: theme.palette.secondary.main,
      backdropFilter: 'blur(37px)',
      borderRadius: 5,
      paddingTop: 5,
      paddingBottom: 4,
      width: 150,
      bottom: 10,
      justifyContent: 'center',
      right: 10
    },
    [`& .${classes.eyeIcon}`]: {
      marginRight: 3,
      marginTop: 5
    },
    [`& .${classes.bidIcon}`]: {
      marginRight: 3,
    },
    [`& .${classes.review}`]: {
      color: '#213E98',
      fontSize: 15
    },
    [`& .${classes.location}`]: {
      marginRight: 3,
      marginTop: 1
    },
    [`& .${classes.flex}`]: {
      display: 'flex',
      marginTop: 7,
      marginLeft: -3
    },
    [`& .${classes.mileage}`]: {
      color: theme.palette.background.default
    },
    [`& .${classes.btn}`]: {
      borderRadius: 7,
      textTransform: 'capitalize'
    },
    [`& .${classes.price}`]: {
      width: 160,
      padding: '20px 5px 0px 5px',
    },
    [`& .${classes.kbb}`]: {
      padding: '20px 5px 0px 5px',
      marginLeft: 30
    },
    [`& .${classes.blackbook}`]: {
      width: 160,
      padding: '20px 5px 0px 5px',
      borderRight: `1px dashed ${theme.palette.border.default}`
    },
    [`& .${classes.detailpanel}`]: {
      background: theme.palette.secondary.grey,
      borderRadius: 10,
      marginTop: 10,
      padding: 22,
      width: '100%',
      height: 85
    },
    [`& .${classes.name}`]: {
      marginTop: 15,
      display: 'flex'
    },
    [`& .${classes.valueFlex}`]: {
      marginTop: 60,
      marginLeft: -3,
      display: 'flex'
    },
    [`& .${classes.box}`]: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    [`& .${classes.errorBlock}`]: {
      width: 205,
      background: '#ffebec',
      color: theme.palette.secondary.main,
      textAlign: 'center',
      padding: 25,
      borderRadius: 6
    },
    [`& .${classes.successBlock}`]: {
      width: 205,
      background: '#d8f5d8',
      color: theme.palette.text.completed,
      textAlign: 'center',
      padding: 25,
      borderRadius: 6
    },
    [`& .${classes.scheduledBlock}`]: {
      width: 250,
      background: '#d8f5d8',
      color: theme.palette.text.main,
      textAlign: 'center',
      padding: 25,
      borderRadius: 6
    },
    [`& .${classes.reserve}`]: {
      float: 'right',
      marginTop: 20,
      textAlign: 'right'
    },
    [`& .${classes.btnGroup}`]: {
      display: 'flex',
      justifyContent: 'right',
      marginTop: 50
    },
    [`& .${classes.bgbtn}`]: {
      backgroundColor: '#e3eaff',
      borderRadius: 7,
      textTransform: 'capitalize',
      color: '#213E98'
    },
    [`& .${classes.bid}`]: {
      padding: 10,
      background: 'lightgrey',
      borderRadius: 6,
      fontSize: 15,
      marginLeft: 10
    },
    [`& .${classes.auctionDetail}`]: {
      padding: 10,
    },
    [`& .${classes.buyNowAmount}`]: {
      float: 'right',
      marginTop: 5,
      textAlign: 'right'
    },
  }
});

const AuctionPanel = ({ auction, handleVehicleClicked }) => {
  const [showBids, setShowBids] = useState(false);
  const [buyOpen, setBuyOpen] = useState(false);
  const [bidOpen, setBidOpen] = useState(false);
  const [reserveOpen, setReserveOpen] = useState(false);
  const [permittedRooftops, setPermittedRooftops] = useState([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isCancelAuctionDialogOpen, setIsCancelAuctionDialogOpen] = useState(false);
  const [isAuctionAdmin, setIsAuctionAdmin] = useState(false);
  

  const dispatch = useDispatch();
  const { search: { quoteOptions }, authentication: { user } } = useSelector(state => state);
  const { rooftopsForUser } = useSelector(state => state.location);

  useEffect(() => {
    setPermittedRooftops( permittedBuyRooftops(user, {}, auction, rooftopsForUser));
  }, [ user, auction, rooftopsForUser ]);
  
  useEffect(() => {
    if (auction) {
      if (auction.auction_type === 'internal_auction' ) {
        setIsAuctionAdmin( auction.isMine === 1 );
      }
      else {
        setIsAuctionAdmin( hasRooftopPermission( auction.dealer_rooftop_id, 'start_auction' ) )
      }
    }
    else {
      setIsAuctionAdmin( false );
    }
  }, [ permittedRooftops, auction ]);

  const handleBidDialogClosed = updated => {
    if (updated) {
    }

    setBidOpen(false);
  }

  const handleVehicleCardClicked = (event) => {
    console.log(event.target);

    handleVehicleClicked(auction);
  }

  const handleShowBidsClicked = (event) => {
    setShowBids(true);

    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const getShipping = (vehicle) => {
    const data = {
      buyerRooftopId: Number(user.rooftop_id),
      sellerRooftopId: auction?.dealer_rooftop_id,
      vin: auction?.vin,
      inventoryId: auction?.token
    }
    dispatch(getReinventShippingOption(data));
  }

  return (
    <StyledGrid key={auction?.token} item xs={12}>
      <Card className={`mt-8 ${classes.card}`}>
        <CardContent>
          <Grid container>
            <Grid item md={4} lg={3} className={classes.imageContainer} onClick={handleVehicleCardClicked}>
              <div className={classes.auction}>
                <Image
                  className={classes.auctionImg}
                  alt="auction photo"
                  src={`https://photos.reinventauto.com/cars/${auction?.photo_name}`}
                />
                {/* <div className={classes.views}>
                  <div>
                    <Image
                      alt="like"
                      src="/static/images/eye.png"
                      className={classes.eyeIcon}
                    />
                  </div>
                  <div>
                    <Typography variant='body1' className={classes.review}>25</Typography>
                  </div>
                </div> */}
                <div className={classes.dealer}>
                  <div>
                    <Image
                      alt="like"
                      src="/static/images/white-location.png"
                      className={classes.location}
                    />
                  </div>
                  <div>
                    <Typography variant='body1' className={classes.mileage}>{auction?.mileage ? `${auction?.mileage?.toLocaleString()}` : 0} Miles</Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item md={8} lg={9} className="m-4">
              <Grid container>
                <Grid item lg={7} className={classes.auctionDetail}>
                  <Typography variant="h5" color="textSecondary">{auction?.title}</Typography>
                  <div className={classes.flex}>
                    <div>
                      {auction?.location ? (<Image
                        alt="like"
                        src="/static/images/location.png"
                      />) :
                        ''}
                    </div>
                    <div>
                      <Typography variant='body1' color="textSecondary">{auction?.location ? `${auction?.location}` : ''}</Typography>
                    </div>
                  </div>
                  <div className={classes.name}>
                    <div>
                      <Typography variant='body1' color="textSecondary">{auction?.dealer_rooftop_name}</Typography>
                    </div>
                  </div>
                  {auction?.seller_rating > 0 &&
                    <div className={classes.name}>
                      <div>
                        <Rating name="read-only" value={auction?.seller_rating} precision={0.1} readOnly />
                      </div>
                    </div>
                  }
                  <div className={classes.valueFlex}>
                    {(auction?.vertical === 'Auto' && auction?.kbb_auction_value) &&
                      <>
                        <div className={classes.auction}>
                          <Typography variant='h5' color='primary'>{auction?.kbb_auction_value ? `${formatDollarAmount(auction?.kbb_auction_value)}` : `$ 0`}</Typography>
                          <Typography variant='body1' color="textSecondary">Kelly Blue Book</Typography>
                        </div>
                        <div className={classes.kbb}>
                          <Typography variant='h5' color='secondary'>{auction?.blackbook_wholesale_value ? `${formatDollarAmount(auction?.blackbook_wholesale_value)}` : `$ 0`}</Typography>
                          <Typography variant='body1' color="textSecondary">Blackbook</Typography>
                        </div>
                      </>}
                  </div>
                </Grid>
                <Grid item lg={5}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box
                        style={{ float: 'right' }}
                        className={classes.box}
                      >
                        {'Scheduled' === auction?.auction_status &&
                          <Box className={classes.scheduledBlock}>
                            <Typography variant="h5">{auction.auction_status.toUpperCase()}</Typography>
                            <Typography variant="h5">{formatDateTime(auction.auction_start_date)}</Typography>
                          </Box>
                        }

                        {['Active', 'Expiring'].includes(auction?.auction_status) &&
                          <AuctionCountdown auction={auction} />
                        }

                        {'Not Sold' === auction?.auction_status &&
                          <Box className={classes.errorBlock}>
                            <Typography variant="h5">{auction.auction_status.toUpperCase()}</Typography>
                          </Box>
                        }

                        {'Canceled' === auction?.auction_status &&
                            <Box className={classes.errorBlock}>
                              <Typography variant="h5">{auction.auction_status.toUpperCase()}</Typography>
                            </Box>
                        }

                        {(auction && ['Sold', 'Pending Sale', 'Pending Transaction', 'Pending Transaction'].includes(auction.auction_status)) &&
                          <Box className={classes.successBlock}>
                            <Typography variant="h5">{auction.auction_status.toUpperCase()}</Typography>
                          </Box>
                        }
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box className={classes.reserve}>
                        {!['Scheduled', 'Canceled'].includes(auction.auction_status) &&
                          (auction?.isReserveMet === 1 ?
                            <Typography color='text.completed'>RESERVE MET</Typography> :
                            <Typography color='secondary'>RESERVE NOT MET</Typography>)
                        }
                        {auction.isBuyWon === 0 &&
                          <Box className="flex mt-1">
                            <Box>
                              <Typography variant="h2">
                                {`${['Sold', 'Pending Sale', 'Pending Transaction'].includes(auction.auction_status) && auction.isBidWon === 1 ? 'Winning' : auction.bids === 0 ? 'Starting' : 'Current'} Bid`}
                              </Typography>
                            </Box>
                            <Box className="ml-2">
                              <Typography variant="h2" color={(['Sold', 'Pending Sale', 'Pending Transaction'].includes(auction.auction_status) && auction.isBidWon === 1) || auction?.isReserveMet === 1 ? 'text.completed' : 'secondary'}>
                                {auction?.current_bid ? formatDollarAmount(auction?.current_bid) : formatDollarAmount(0)}
                              </Typography>
                            </Box>
                          </Box>
                        }
                        {auction?.mine === 1 && auction.auction_type !== 'internal_auction' && (
                          <Box className="flex mt-1">
                            <Box>
                              <Typography variant="h2">
                                Reserve Price
                              </Typography>
                            </Box>
                            <Box className="ml-2">
                              <Typography
                                variant="h2"
                              >
                                {auction?.reserve_price ? formatDollarAmount(auction?.reserve_price) : formatDollarAmount(0)}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {(auction?.buy_it_now_price > 0 && auction.isBidWon === 0 && auction.current_bid <= auction?.buy_it_now_price) && (
                          <Box className="flex mt-1">
                            <Box>
                              <Typography
                                variant="h2"
                              >
                                Buy Now Price
                              </Typography>
                            </Box>
                            <Box className="ml-2">
                              <Typography
                                variant="h2"
                                color={['Scheduled', 'Active', 'Expiring', 'Not Sold'].includes(auction.auction_status) ? '' : 'text.completed'}
                              >
                                {formatDollarAmount(auction?.buy_it_now_price)}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {(auction?.isBidWon === 1 && auction?.mine === 0) &&
                          (auction.your_bid === 1 ?
                            <Box>
                              <Box className="flex mt-2">
                                <Typography variant='h5' color="text.completed">Congratulations!  You won!</Typography>
                              </Box>
                            </Box>
                            :
                            <Box>
                              <Box className="flex mt-2">
                                <Typography variant='h5' color="secondary">Auction Ended.  Sorry try again!</Typography>
                              </Box>
                            </Box>)
                        }
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <BidsDialog
                        auction={auction}
                        handleClose={() => setShowBids(false)}
                        isOpen={showBids}
                      />

                      <BuyNowDialog
                        isOpen={buyOpen}
                        handleClose={() => setBuyOpen(false)}
                        vehicle={auction}
                        transportProvider={quoteOptions}
                      />

                      <PlaceBidDialog
                        isOpen={bidOpen}
                        handleClose={handleBidDialogClosed}
                        auction={auction}
                        transportProvider={quoteOptions}
                      />

                      <UpdateReservePriceDialog
                        open={reserveOpen}
                        handleClose={() => setReserveOpen(false)}
                        auction={auction}
                      />

                      <CancelAuctionDialog
                      isOpen={isCancelAuctionDialogOpen}
                      auction={auction}
                      handleClose={() => setIsCancelAuctionDialogOpen(false)}
                       />

                      <ConfirmSaleDialog
                        isOpen={isConfirmDialogOpen}
                        token={isConfirmDialogOpen ? auction?.token : ''}
                        offerToken={isConfirmDialogOpen ? auction?.auction_token : ''}
                        action={isConfirmDialogOpen ? 'auctionSuccess'  : ''}
                        handleClose={() => {
                          if (auction.isBidWon === 1) {
                            dispatch(getBuyer());
                          } else {
                            dispatch(loadOffer());
                          }
                          setIsConfirmDialogOpen(false);
                        }}
                      />

                      <Box className={classes.btnGroup} sx={{flexDirection: { xs: "column", sm: "row"}}}>
                        <Box className="ml-3" sx={{mt: {xs: 3, sm: 0}}}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.bgbtn}
                            onClick={handleShowBidsClicked}
                          >
                            <Image
                              alt="bid"
                              src="/static/images/bid.png"
                              className={classes.bidIcon}
                            />
                            {auction?.bids} Bids
                          </Button>

                          {['Active', 'Expiring'].includes(auction?.auction_status) && auction?.your_bid === 1 && user && hasBidPermissionOnHighestBid(user, auction) &&
                            <div style={{ marginTop: 10 }}>
                              <Typography
                                variant="body"
                                color='primary'>You have highest bid</Typography>
                            </div>
                          }
                        </Box>

                        {auction?.buy_it_now_price > 0 &&
                          ['Active', 'Expiring'].includes(auction?.auction_status) &&
                          auction.mine === 0 && auction.current_bid <= auction?.buy_it_now_price && (
                            <Box className="ml-3" sx={{mt: {xs: 3, sm: 0}}}>
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.btn}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setBuyOpen(true);
                                  getShipping(auction);
                                }}
                              >
                                Buy Now
                              </Button>
                            </Box>
                          )
                        }

                        <Box className="ml-3" sx={{mt: {xs: 3, sm: 0}}}>
                          <Button
                            disabled={!(['Active', 'Expiring'].includes(auction?.auction_status) &&
                            (auction.auction_type === 'internal_auction' || auction?.mine === 0) &&
                              auction?.ended === 0 &&
                              permittedRooftops?.length > 0)}
                            variant="contained"
                            color="primary"
                            className={classes.btn}
                            size="large"
                            onClick={(e) => {
                              e.stopPropagation();
                              setBidOpen(true);
                              getShipping(auction);
                            }}
                            >
                            Place bid
                          </Button>
                        </Box>
                        {(['Scheduled', 'Active', 'Expiring'].includes(auction?.auction_status) &&
                                auction.auction_type !== 'internal_auction' &&
                                auction?.mine === 1) &&
                            <Box className="ml-3" sx={{mt: {xs: 3, sm: 0}}}>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.btn}
                              size="large"
                              onClick={(e) => {
                                e.stopPropagation();
                                setReserveOpen(true);
                              }}
                            >
                              Update Reserve Price
                            </Button>
                          </Box>
                        }

                        {(!['Canceled', 'Sold', 'Not Sold', 'Pending Sale', 'Pending Transaction'].includes(auction.auction_status) &&
                                isAuctionAdmin) && (
                            <Box className="ml-3" sx={{mt: {xs: 3, sm: 0}}}>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.btn}
                              size="large"
                              onClick={(e) => {
                                e.stopPropagation();
                               setIsCancelAuctionDialogOpen(true)
                              }}
                            >
                              Cancel Auction
                            </Button>
                          </Box>
                        )}


                        {auction?.mine === 1 && auction?.auction_status === 'Pending Sale' &&
                            <Box className="ml-3" sx={{mt: {xs: 3, sm: 0}}}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              className={classes.bgbtn}
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsConfirmDialogOpen(true);
                              }}
                            >
                              Approve Sale
                            </Button>
                          </Box>
                        }
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </StyledGrid>
  )
};

export default AuctionPanel;