export const hasRooftopPermission = (user, rooftopId, permissionName) => {
    let relevantPermissions = user.permissionsData.find(({dealer_rooftop_id, permission_name}) => parseInt(dealer_rooftop_id) === parseInt(rooftopId) && permission_name === permissionName);
    return relevantPermissions !== undefined;
}

export const hasAPermission = (user, permissionName) => {
    let relevantPermissions = user.permissionsData ? user.permissionsData.find(({permission_name}) => permission_name === permissionName) : undefined;
    return relevantPermissions !== undefined;
}

export const hasEditUsersPermissionForOneOfUsersRooftops = (loggedInUser, userToEdit) => {
    let hasAccess = false;
    userToEdit.locationAccess.forEach((rooftop) => {
        if(hasRooftopPermission(loggedInUser, rooftop.dealerRooftopId, 'edit_users')){
            hasAccess = true
        }
    });
    return hasAccess;
}

export const getInitials = (name) => {
    if (name) {
    const splitName = name.split(" ");
    const newInitials = `${splitName[0][0]}${splitName[1][0]}`;
    return newInitials;
    }
    return '';
  };
  
export const permittedBuyRooftops = (user, vehicle, auction, rooftopsForUser) => {
    let permittedRooftopsForUser;
    let vehicleIsInternal;
    let vehicleRooftopId;
    let lastBidBuyerRooftopId;

    if(user && rooftopsForUser && (vehicle || auction)){
        if(Object.keys(vehicle).length > 0){
            vehicleIsInternal = vehicle.is_internal_vehicle;
            vehicleRooftopId = vehicle.dealer_rooftop_id;
        } else {
            vehicleIsInternal = auction.auction_type === 'internal_auction';
            vehicleRooftopId = auction.dealer_rooftop_id;
            lastBidBuyerRooftopId = auction.last_bid_buyer_rooftop_id;
        }

        if(vehicleIsInternal){
            permittedRooftopsForUser = rooftopsForUser.filter((rooftop) => hasRooftopPermission(user, rooftop.dealerRooftopId, 'purchase_vehicle_from_any_rooftop_owned_by_users_dealer'));
        }
        else {
            permittedRooftopsForUser = rooftopsForUser.filter((rooftop) => hasRooftopPermission(user, rooftop.dealerRooftopId, 'purchase_vehicle_from_any_rooftop_owned_by_a_different_dealer'));
        }

        permittedRooftopsForUser = permittedRooftopsForUser.filter((rooftop) => rooftop.dealerRooftopId !== vehicleRooftopId);
        
        if(lastBidBuyerRooftopId){
            permittedRooftopsForUser = permittedRooftopsForUser.filter((rooftop) => rooftop.dealerRooftopId !== lastBidBuyerRooftopId);
        }
    }

    return permittedRooftopsForUser;
}

export const hasBidPermissionOnHighestBid = (user, auction) => {
    const auctionIsInternal = auction.auction_type === 'internal_auction';
    
    return (!auctionIsInternal && hasRooftopPermission(user, auction.last_bid_buyer_rooftop_id, 'purchase_vehicle_from_any_rooftop_owned_by_a_different_dealer')) ||
        (auctionIsInternal && hasRooftopPermission(user, auction.last_bid_buyer_rooftop_id, 'purchase_vehicle_from_any_rooftop_owned_by_users_dealer'));
}
