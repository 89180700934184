import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import DetailsPanel from "components/vehicleDetailPanel";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import queryString from 'query-string';
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import InventoryCard from "../../components/InventoryCard";
import Button from "@mui/material/Button";
import Image from "../../components/widgets/Image";
import Breadcrumbs from "components/widgets/Breadcrumbs";
import ConfirmSaleDialog from "../../components/dialogs/ConfirmSaleDialog";

const PREFIX = 'Details';

const classes = {
  typo: `${PREFIX}-typo`,
  box: `${PREFIX}-box`,
  photoContainer: `${PREFIX}-photoContainer`,
  makebtn: `${PREFIX}-make-btn`,
  bidIcon: `${PREFIX}-bidIcon`,
  condition: `${PREFIX}-condition`
}

const StyledGrid = styled(Grid)(({
 theme
}) => {
  return {
    [`& .${classes.typo}`]: {
      marginTop: -2,
      color: theme.palette.border.default
    },
    [`& .${classes.box}`]: {
      padding: 10,
      borderBottom: `1px solid ${theme.palette.border.default}`
    },
    [`& .${classes.photoContainer}`]: {
      maxHeight: 750,
      [`& img`]: {
        maxHeight: 600
      }
    },
    [`& .${classes.makebtn}`]: {
      borderRadius: 7,
      background: 'rgba(33, 62, 152, 0.2)',
      color: theme.palette.primary.main,
      fontWeight: 600,
      marginTop: 10,
      width: '100%',
      textTransform: 'capitalize'
    },
    [`& .${classes.bidIcon}`]: {
      marginRight: 3,
    },
    [`& .${classes.condition}`]: {
      display: 'flex',
      justifyContent: 'space-between'
    },
  }
});

const VehicleView = () => {
  const { id, action } = useParams();
  const location = useLocation();

  const [offerToken, setOfferToken] = useState();
  const [offerAction, setOfferAction] = useState();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState();

  const {
      search: {
          vehicleDetail,
      }
  } = useSelector(state => state);

  const {
      vehicle,
      inventory
  } = vehicleDetail;

  useEffect(() => {
    if (location?.search?.length > 0 && vehicleDetail.myDealer !== undefined) {
      const { action: queryAction, token: queryToken } = queryString.parse(location.search.replace('&amp;', '&'));
      if (action) {
        setOfferToken(queryToken);
        setOfferAction(queryAction);
        setIsConfirmDialogOpen(true);
      }
    }
  }, [JSON.stringify(location), JSON.stringify(vehicleDetail)]);

  return (
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "Home",
                type: "link",
                to: "/buy",
                className: "text-blue-dark",
              },

              location.pathname === "/auctions"
                ? {
                    label: "Auctions",
                    type: "link",
                    to: "/auctions",
                  }
                : null,

              location.pathname === "/buy"
                ? {
                    label: "Buy",
                    type: "button",
                    callback: () => {},
                  }
                : {
                    label: "Sell",
                    type: "button",
                    callback: () => {},
                  },
              {
                label: vehicle?.title,
                type: "span",
              },
            ]}
          />

        <Grid item xs={12}>
          <DetailsPanel
              id={id}
              offerToken={offerToken}
              offerAction={offerAction}
              vehicle={vehicle}
          />
        </Grid>

        <Grid item xs={12}>
          <Box className={`mt-5 ${classes.box}`}>
            <Typography variant='h2'>MORE VEHICLES FROM THIS DEALER</Typography>
          </Box>

          <Grid container spacing={2}>
            {inventory &&
                inventory.map(vehicle =>
                    <InventoryCard
                        id={vehicle.token}
                        vehicle={vehicle}
                        classes={classes}
                        action={action}
                    />)
            }
            <Grid item xs={12}>
              <center>
                <Button
                    variant="contained"
                    size="large"
                    className={classes.viewMore}
                >
                  <Box className="flex">
                    <Box>
                      <Typography variant="h4">SEE ALL INVENTORY</Typography>
                    </Box>
                    <Box className="ml-28">
                      <Image
                          src="/static/images/rightArrow.png"
                      />
                    </Box>
                  </Box>
                </Button>
              </center>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmSaleDialog
          isOpen={isConfirmDialogOpen}
          token={vehicle?.token}
          offerToken={offerToken}
          action={offerAction}
          handleClose={() => { setIsConfirmDialogOpen(false) }}
      />
  </Grid>
  );
}

export default VehicleView;